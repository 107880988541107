<template>
  <div class="turbo-fusion pb50">
    <div class="banner">
      <ImageWithPlaceholder
        src="/image/turbo/tb0.jpg"
        alt="In-cabin Visual Software Solutions"
      />

      <div class="in-cabin-box">
        <h1 class="in-cabin-title">Turbo Fusion Products for Smartphone</h1>
        <div class="item-list">
          <a href="#image-quality" class="image-quality"
            ><span>Image</span><span> Quality</span>
          </a>
          <a href="#turbo-fusion-portrait" class="portrait">Portrait</a>
          <a href="#instant-capture" class="capture-instant"
            ><span>Instant</span> <span>Capture</span></a
          >
          <a href="#turbo-fusion-super-resolution" class="super-resolution"
            ><span>Super </span><span> Resolution</span></a
          >
          <a href="#turbo-fusion-video" class="video"> Video </a>
        </div>
      </div>
    </div>
    <div class="content mt120" id="image-quality">
      <p class="p-text">
        To better capture the real world around us, ArcSoft created a family of
        digital photography products based on intelligent algorithms ready for
        licensing to smartphone makers.
      </p>
    </div>
    <div class="quality-warpper mt50">
      <div class="content">
        <h2 class="h2-title h2-quality">Turbo Fusion™ Image Quality</h2>
      </div>
      <div class="hdr-warpper img-box mt30">
        <div class="lr-bg-box flex-start">
          <div class="box-text flex-end hdr-text">
            <div class="box-text-box">
              <h4 class="h4-text">Turbo Fusion™ HDR</h4>
              <p class="mt30">
                ArcSoft Turbo Fusion™ HDR allows users to capture a photo with
                better tone and more realistic light and shadow, enhancing the
                highlight and dark performance of the sky clouds and clothing
                details in the photos, making the photos more vivid, more
                realistic and more delicate.
              </p>
            </div>
          </div>
          <div class="box-img"><img src="/image/turbo/tb1.png" alt="" /></div>
        </div>
        <div class="lr-bg-box flex-end">
          <div class="box-img">
            <img src="/image/turbo/tb2.jpg" alt="" />
          </div>
          <div class="box-text super-night-text">
            <div class="box-text-box">
              <h4 class="h4-text">Turbo Fusion™ SuperNight</h4>
              <p class="mt30">
                ArcSoft Turbo Fusion™ SuperNight help capture a photo at night
                with wider dynamic range, amazing clarity,and less artifacts, by
                using advanced S/W de-mosaic to replace H/W de-mosaic to provide
                better fusion result.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content mb126">
        <h4 class="mt50">Turbo Fusion™ Sunset</h4>
        <p class="mt30">
          ArcSoft advanced multi-frame Turbo Fusion™ engine provides image
          quality surpass expectations, with optimized tone mapping effects for
          sunset scenes, increasing the sense of range and energy of sunset
          scenes.
        </p>
        <div class="img-box">
          <img class="mt30" src="/image/turbo/tb3.jpg" alt="" />
        </div>

        <h4 class="mt50">Turbo Fusion™ Neutral-Density Filter (NDF)</h4>
        <p class="mt30">
          ArcSoft NDF products leverage multiple frames fusion and AI technology
          to generate the results with very similar quality compared to real ND
          filters. ND filter number is supported from 2 to 1024.
        </p>
        <div class="img-box">
          <img class="mt30" src="/image/turbo/tb4.jpg" alt="" />
        </div>

        <div class="img-lr mt20">
          <img src="/image/turbo/tb5.jpg" alt="" />
          <img src="/image/turbo/tb6.jpg" alt="" />
        </div>
        <div class="img-box">
          <img class="mt20" src="/image/turbo/tb7.jpg" alt="" />
        </div>
      </div>
      <div class="star-finder">
        <div class="content">
          <h4 class="pt80">Turbo Fusion™ StarFinder</h4>
          <p class="mt30">
            Leveraging global positioning and smartphone sensor data, our
            technology swiftly identifies the constellations visible in the
            camera’s view, guiding users for optimal star photography.
            Additionally, precise star map alignment allows constellation
            stickers to be added to the final photos, enhancing the fun and
            creativity of stargazing.
          </p>
          <div class="mt50 text-img">
            <img src="/image/turbo/star1.jpg" alt="" />
            <p>
              Utilizing precise star coordinate data, we enhance individual
              stars in nightscape photos for a more vivid and detailed view of
              the night sky
            </p>
          </div>

          <div class="text-img">
            <p>
              Using constellation data to create a variety of constellation
              stickers, adding an element of fun to your stargazing photography
              experience
            </p>
            <img src="/image/turbo/star2.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="content mt80 mb24">
        <h4 class="mb24">Turbo Fusion™ Panaroma Maker (PMK)</h4>
        <p>
          ArcSoft Turbo Fusion™ PMK engine helps to create bigger and
          higher-quality panoramic photos with a series of burst shots.
        </p>
      </div>
      <div class="img-box mb60">
        <img src="/image/turbo/panaroma.jpg" alt="" />
      </div>
      <p class="content mb60">
        Because of the better quality of image stitching, ArcSoft PMK engine
        supports all type of camera for panorama shots, like ultra-wide, wide,
        tele PMK.
      </p>
      <div class="panaroma-lr mt20">
        <div class="img-box mb24">
          <img src="/image/turbo/panaroma1.jpg" alt="" />
        </div>
        <div class="img-box">
          <img src="/image/turbo/panaroma2.jpg" alt="" />
        </div>
      </div>
    </div>

    <div id="turbo-fusion-portrait" class="turbo-fusion-portrait">
      <div class="bg-box mb40">
        <div class="bg-color"></div>
        <div class="content turbo-fusion-portrait-top pt80">
          <h2 class="h2-title sketch-title mb30">Turbo Fusion™ Portrait</h2>
          <h3 class="h3-title mb20">Turbo Fusion™ Bokeh with HDR</h3>
          <p class="mb20">
            This solution is perfect to enable bokeh effect under backlight
            conditions by using ArcSoft™ AI Portrait Segmentation to enhance the
            nature detail on both foreground and background of a bokeh picture.
          </p>
          <div class="img-box mb60">
            <img src="/image/turbo/portrait1.jpg" alt="" />
          </div>
          <h3 class="h3-title mb20 color-fff">
            Turbo Fusion™ Bokeh with Advanced Hair Results
          </h3>
          <p class="mb32 color-fff fs14">With better hair bokeh results.</p>
        </div>

        <div class="img-container">
          <div class="img-box">
            <img src="/image/turbo/portrait2.jpg" alt="" />
          </div>
          <div class="img-box">
            <img src="/image/turbo/portrait3.jpg" alt="" />
          </div>
          <div class="img-box">
            <img src="/image/turbo/portrait4.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="content mb40">
        <h3 class="h3-title mb24">
          Portrait capturing with Neutral-Density Filter (NDF)
        </h3>
        <p class="mb40">for better quality of both water and portrait</p>
      </div>
      <div class="portrait-ndf-top mb24">
        <div><img src="/image/turbo/portrait5.jpg" alt="" /></div>
        <div><img src="/image/turbo/portrait6.jpg" alt="" /></div>
      </div>
      <div class="content mb40">
        <div class="portrait-ndf-middle">
          <img src="/image/turbo/portrait7.jpg" alt="" />
        </div>
      </div>

      <div class="content mb40">
        <p class="mb24">Turbo Fusion™ Indian</p>
        <p class="mb40">
          ArcSoft advanced multi-frame Turbo Fusion™ engine provides more
          details and better clarity, tailored to the characteristics of Indian
          people, with improved dark circles and more natural eyes.
        </p>
        <div class="img-box mb40">
          <img src="/image/turbo/india.jpg" alt="" />
        </div>
      </div>
    </div>

    <div id="instant-capture" class="instant-capture">
      <div class="content mb50">
        <h2 class="h2-title sketch-title mb46">Instant Capture</h2>
        <div class="instant-capture-item">
          <div class="img-box">
            <img src="/image/turbo/capture1.jpg" alt="" />
            <!-- <p>Smooth Optimization of moving edges</p> -->
          </div>
          <div class="img-box">
            <img src="/image/turbo/capture2.jpg" alt="" />
            <!-- <p>Optimized resolution for moving area</p> -->
          </div>
        </div>
      </div>
    </div>

    <div
      id="turbo-fusion-super-resolution"
      class="turbo-fusion-super-resolution pt80"
    >
      <h2 class="h2-title sketch-title content mb46">
        Turbo Fusion™ Super Resolution
      </h2>
      <div class="super-resolution-item">
        <div class="img-box mb40">
          <img src="/image/turbo/super1.jpg" alt="" />
        </div>
        <div class="img-box">
          <img src="/image/turbo/super2.png" alt="" />
        </div>
      </div>
    </div>
    <div id="turbo-fusion-video" class="turbo-fusion-video pt80">
      <div class="content mb50">
        <h2 class="h2-title sketch-title mb46">Turbo Fusion™ Video</h2>
        <h3 class="h3-title mb30">Turbo Fusion™ Video SuperNight</h3>
        <p class="mb24">
          ArcSoft Turbo Fusion™ Video SuperNight with AI engine help to reduce
          image noises，improve contrasts, and restore texture details with
          temporal frames in the night scenes.
        </p>
        <div class="img-box mb40">
          <img src="/image/turbo/video2.png" alt="" />
        </div>
        <div class="img-box mb32">
          <img src="/image/turbo/video1.png" alt="" />
        </div>
        <!-- <p class="text-center mb60">TFP Video SuperNight</p> -->
        <!-- <h3 class="mb24">Turbo Fusion™ Tone Mapping Color (TMC) Video</h3> -->
        <!-- <p class="mb32">
          By leverage high efficiency Turbo Fusion™ video tone adjustment
          algorithms, this video solution generates a real-time video stream
          with better local contrast enhancement and optimized viewing tone.
        </p> -->
        <h3 class="mb24">Low Light Video</h3>
        <p class="mb32 fs14">
          The night video product improves video capture with brighter, cleaner,
          and more detailed quality without side effects.
        </p>
        <div class="night-hawk-box mb32">
          <div class="img-box">
            <img src="/image/turbo/lowlight0.png" alt="" />
          </div>
        </div>
        <h3 class="h3-title mb32">Video Stabilizer</h3>
        <p class="mb32 fs14">
          This ArcSoft product uses real-time video stabilization to smooth the
          panning and rotary scenes
        </p>
        <div class="img-box mb32">
          <img src="/image/turbo/stabilizer.jpg" alt="" />
        </div>
        <h3 class="h3-title mb24">Selfie Video Stabilizer</h3>
        <div class="img-box">
          <img src="/image/turbo/stabilizer2.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  name: 'TurboFusion',
  components: {
    ImageWithPlaceholder,
  },
  metaInfo: {
    title:
      'Turbo Fusio Products for Smartphone: Image Quality, portrait, Instant Capture, SuperResolution, and Video',
    meta: [
      {
        name: 'description',
        content: `Turbo Fusion Platform comprehensively enhances the imaging and display performance of smartphones, across various aspects such as image quality, portraiture, tonal balance, candid shots, and color accuracy.`,
      },
      {
        name: 'keywords',
        content: `Turbo Fusio Image Quality, Turbo Fusion HDR, Turbo Fusion Sunset, Turbo Fusion Neutral-Density Filter (NDF), Turbo Fusion StarFinder,Turbo Fusion Panaroma Maker, Turbo Fusion Portrait, Bokeh with HDR,Portrait capturing with Neutral-Density Filter (NDF), Fast Indian, Instant Capture, Super Resolution, Video SuperNight, Tone Mapping Color (TMC) Video, Low Light Video, Video Stabilizer`, // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
p {
  font-size: 16px;
}
.turbo-fusion {
  overflow: hidden;
  .p-text {
    color: #222;
    line-height: 18px;
    padding: 0 32px;
    margin: 10px 0;
  }
  .h4-text {
    font-size: 20px;
  }
  .h2-title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    background: #e83021;
    vertical-align: bottom;
    margin-right: 24px;
  }
  // .h3-title {
  //   height: 24px;
  //   line-height: 24px;
  //   font-size: 18px;
  //   text-indent: 32px;
  //   font-weight: normal;
  //   margin-bottom: 10px;
  // }
  .supports-box {
    width: 109px;
    height: 41px;
    background-color: #e83021;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 41px;
    text-align: center;
  }
  .content {
    max-width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 0.1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .banner {
    width: 100%;
    position: relative;
    .in-cabin-box {
      margin: 0 auto;
      position: absolute;
      z-index: 10;
      bottom: -60px;
      left: calc(50% - 500px);
      .in-cabin-title {
        font-weight: normal;
        letter-spacing: 2px;
        font-size: 54px;
        color: #fff;
        text-align: center;
      }
      .item-list {
        margin-top: 246px;
        height: 120px;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #eaeaeb, #fff);
        padding: initial;
        a {
          font-size: 20px;
          width: 200px;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          padding: 15px 25px;
          position: relative;

          span {
            display: inline-block;
            width: 150px;
          }
          &:hover::after {
            display: block;
          }
          &:hover {
            color: #e83021;
          }
        }
        a:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .image-quality {
          background: url(/image/turbo/image-quality.svg) no-repeat 90% 18px
              e('/') 22px 22px,
            url(/image/turbo/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/turbo/image-quality-action.svg) no-repeat 90%
                18px e('/') 22px 22px,
              url(/image/turbo/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .portrait {
          background: url(/image/turbo/portrait.svg) no-repeat 85% 18px e('/')
              22px 22px,
            url(/image/turbo/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/turbo/portrait-action.svg) no-repeat 85% 18px
                e('/') 22px 22px,
              url(/image/turbo/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .super-resolution {
          background: url(/image/turbo/super-resolution.svg) no-repeat 85% 15px
              e('/') 22px 22px,
            url(/image/turbo/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/turbo/super-resolution-action.svg) no-repeat
                85% 15px e('/') 22px 22px,
              url(/image/turbo/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .capture-instant {
          background: url(/image/turbo/capture-instant.svg) no-repeat 85% 15px
              e('/') 22px 22px,
            url(/image/turbo/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/turbo/capture-instant-action.svg) no-repeat
                85% 15px e('/') 22px 22px,
              url(/image/turbo/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .video {
          background: url(/image/turbo/video.svg) no-repeat 85% 15px e('/') 22px
              22px,
            url(/image/turbo/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/turbo/video-action.svg) no-repeat 85% 15px
                e('/') 22px 22px,
              url(/image/turbo/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
      }
    }
  }
  .quality-warpper {
    .panaroma-lr {
      display: flex;
      flex-direction: column;
      // aspect-ratio: 1920 / 300;
    }
    .img-box {
      max-width: 1920px;
      margin: 0 auto;
    }
    .h2-quality {
      font-size: 40px;
      color: #222;
    }
    .flex-end {
      justify-content: flex-end;
    }
    .flex-start {
      justify-content: flex-start;
    }
    .lr-bg-box {
      display: flex;
      .box-text {
        width: 50%;
        // height: 400px;
        display: flex;
        align-items: center;
        .box-text-box {
          width: 480px;
          padding: 0 20px;
          p {
            width: 450px;
          }
        }
      }
      .box-img {
        overflow: hidden;
        flex: 1;
        img {
          display: block;
        }
      }
      .super-night-text {
        box-sizing: border-box;
        background-color: #222;
        color: #fff !important;
        flex: 1;
        box-sizing: border-box;
        // aspect-ratio: 960 / 400;
      }
      .hdr-text {
        background-color: #fafafa;
      }
    }
    .img-lr {
      display: flex;
      justify-content: space-between;
      aspect-ratio: 960 / 460;
      img {
        height: 100%;
      }
    }
    .star-finder {
      background-color: #222;
      padding-bottom: 50px;
      color: #fff;

      .text-img {
        display: flex;
        align-items: center;
        aspect-ratio: 960 / 270;
        img {
          height: 100%;
        }
        p {
          padding: 20px;
        }
      }
    }
  }

  //
  .h3-title {
    font-size: 20px;
  }
  .sketch-title {
    font-size: 40px;
    color: #222;
  }

  .turbo-fusion-portrait {
    position: relative;
    .bg-box {
      background-color: #222222;
      padding-bottom: 35px;
    }
    .bg-color {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      height: 500px;
    }
    .turbo-fusion-portrait-top {
    }

    .img-container {
      max-width: 1460px;
      margin: 0 auto;
      display: flex;
      gap: 40px;
      .img-box {
        flex: 1;
      }
    }
  }
  .portrait-ndf-top {
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    > div {
      flex: 1;
      aspect-ratio: 960 / 460;
    }
    img {
      width: 100%;
    }
  }
  .portrait-ndf-middle {
    aspect-ratio: 882 / 468;
    width: 882px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .instant-capture {
    background-color: #222222;
    color: #fff;
    padding: 80px 0;
    .h2-title {
      color: #fff;
    }
    .instant-capture-item {
      display: flex;
      gap: 40px;
      .img-box {
        flex: 1;
        // aspect-ratio: 460 / 500;
        p {
          font-size: 20px;
          text-align: center;
          color: #fff;
          margin-top: 20px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .turbo-fusion-super-resolution {
    .super-resolution-item {
      width: 1414px;
      aspect-ratio: 1414 / 1169;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .turbo-fusion-video {
    .img-box {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

.img-box img {
  width: 100%;
}

.text-center {
  text-align: center;
}

.fs14 {
  font-size: 14px !important ;
}

.color-fff {
  color: #ffffff !important;
}
</style>
